<template>
  <div>
    <!-- 페이지 -->
    <div class="pagination-group">
      <div class="pagination-btn unselect" @click="prevPage">이전</div>
      <div class="pagination-num"
           :class="{ 'pagination-num-selected' : n===filtered.page_num }"
           @click="changePage(n)"
           v-for="(n,idx) in filtered.total_page" :key="'page'+idx">{{ n }}</div>
      <div class="pagination-btn unselect" @click="nextPage">다음</div>
    </div>
  </div>

</template>

<script>
  export default {
    props: ['filtered'],
    data() {
      return {}
    },
    created() {
    },
    methods: {
      changePage(num) {
        this.filtered.page_num = num;
        this.filtered.cur_page = num;
        this.$emit('getData');
      },
      prevPage() {
        if (this.filtered.page_num > 1) {
          this.filtered.page_num -= 1;
          this.$emit('getData');
        }
      },
      nextPage() {
        if (this.filtered.page_num < this.filtered.total_page) {
          this.filtered.page_num += 1;
          this.$emit('getData');
        }
      },
    }
  }
</script>

<style scoped>
  .pagination-group {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 20px;
  }

  .pagination-btn {
    cursor: pointer;
    border: 1px solid #eaeaea;
    color: #bababa;
    padding: 6px 14px;
    border-radius: 3px;
    font-size: 12px;
  }
  .pagination-btn:hover {
    color: #787878;
    border: 1px solid #cccccc;
  }


  .pagination-btn:first-child {
    margin-right: 8px;
  }

  .pagination-btn:last-child {
    margin-left: 8px;
  }

  .pagination-prev i
  .pagination-next i {
    color: #18a4ed;
    margin: 4px;
    cursor: pointer;
  }

  .pagination-num {
    padding: 6px 10px;
    cursor: pointer;
    font-size: 14px;
    color: #bababa;
  }

  .pagination-num-selected {
    border-radius: 4px;
    color: #222831;
    background-color: #f7f7f7;
  }

</style>
